import React from 'react';
import './Page.css';
import styles from './App.css';
import OG from './feeds/OG';
import {Schema} from './feeds/Schema';
import {Footer} from './Footer';
import {Header} from './Header';
import {Main} from './Main';

export let App = () => {
	return <div className={styles.root}>
		<Header />
		<OG />
		<Main />
		<Footer />
		<Schema />
	</div>;
};

